.tui-select-box {
  position: relative;
}

.tui-select-box-input,
.tui-select-box-dropdown,
.tui-select-box-item-group-label,
.tui-select-box-item {
  box-sizing: border-box;
}

.tui-select-box-input {
  border: 1px solid #ddd;
}

.tui-select-box-input.tui-select-box-open,
.tui-select-box-dropdown {
  border: 1px solid #aaa;
}

.tui-select-box-input,
.tui-select-box-dropdown {
  background: #fff;
}

.tui-select-box-input,
.tui-select-box-item-group-label,
.tui-select-box-item {
  padding: 0 8px;
  height: 29px;
  font-size: 13px;
  color: #333;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.tui-select-box-placeholder,
.tui-select-box-item-group-label,
.tui-select-box-item {
  line-height: 29px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tui-select-box-placeholder,
.tui-select-box-icon {
  height: 100%
}

.tui-select-box-placeholder {
  display: inline-block;
  margin: 0;
  width: 80%;
  width: calc(100% - 12px);
  vertical-align: sub;
}

.tui-select-box-icon {
  display: block;
  float: right;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAECAYAAACHtL/sAAAAAXNSR0IArs4c6QAABBFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iCiAgICAgICAgICAgIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIgogICAgICAgICAgICB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iCiAgICAgICAgICAgIHhtbG5zOnRpZmY9Imh0dHA6Ly9ucy5hZG9iZS5jb20vdGlmZi8xLjAvIj4KICAgICAgICAgPHhtcE1NOkRlcml2ZWRGcm9tIHJkZjpwYXJzZVR5cGU9IlJlc291cmNlIj4KICAgICAgICAgICAgPHN0UmVmOmluc3RhbmNlSUQ+eG1wLmlpZDozOTU0MDZFM0JEQjExMUU2OEQ1MkUyN0M0NDdEMkIxMTwvc3RSZWY6aW5zdGFuY2VJRD4KICAgICAgICAgICAgPHN0UmVmOmRvY3VtZW50SUQ+eG1wLmRpZDozOTU0MDZFNEJEQjExMUU2OEQ1MkUyN0M0NDdEMkIxMTwvc3RSZWY6ZG9jdW1lbnRJRD4KICAgICAgICAgPC94bXBNTTpEZXJpdmVkRnJvbT4KICAgICAgICAgPHhtcE1NOkRvY3VtZW50SUQ+eG1wLmRpZDozOTU0MDZFNkJEQjExMUU2OEQ1MkUyN0M0NDdEMkIxMTwveG1wTU06RG9jdW1lbnRJRD4KICAgICAgICAgPHhtcE1NOkluc3RhbmNlSUQ+eG1wLmlpZDozOTU0MDZFNUJEQjExMUU2OEQ1MkUyN0M0NDdEMkIxMTwveG1wTU06SW5zdGFuY2VJRD4KICAgICAgICAgPHhtcDpDcmVhdG9yVG9vbD5BZG9iZSBQaG90b3Nob3AgQ1M2IChXaW5kb3dzKTwveG1wOkNyZWF0b3JUb29sPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4K/ZqkVgAAAERJREFUGBljNDY2/s+AA5w9e5YRXQqqXgAo9xEkB1aAzRA8mmFmgg1hgvIEYKIgmgjNIGUfQATciUBX8IMEidQM0gsGAPabHJ7Zbgx5AAAAAElFTkSuQmCC) left center no-repeat;
  width: 7px;
  overflow: hidden;
  text-indent: 100%;
}

.tui-select-box-open > .tui-select-box-icon {
  background-position: right center;
}

.tui-select-box-dropdown,
.tui-select-box-item-group {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.tui-select-box-dropdown {
  position: absolute;
  border-top: none;
  /* max-height: 145px; */
  overflow: auto;
  z-index: 1;
  width: 100%;
  overflow-x: hidden;
}

.tui-select-box-item-group-label {
  display: block;
  font-weight: bold;
  cursor: default;
}

.tui-select-box-item-group > .tui-select-box-item {
  padding-left: 20px;
}

.tui-select-box-selected {
  background: #f4f4f4;
}

.tui-select-box-highlight {
  background: #e5f6ff;
  outline: none;
}

.tui-select-box-disabled {
  background: #f9f9f9;
  color: #c8c8c8;
  cursor: default;
}

.tui-select-box-hidden {
  display: none; /* for test */
}